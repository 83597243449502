import React from "react"
import Layout from "../../components/Layout/Layout"
import Order from "../../components/Epharmacy/Order"

export default ({ location }) => {
  return (
    <Layout
      title="Order Medicines"
      subtitle="Search for medicines and add them to your cart."
      currentStep={1}
      totalSteps={4}
      process="epharmacy"
      seoTitle="Order Medicines"
      display={{ footer: false, helpCenterBanner: false, radialStepper: true }}
      path={location?.pathname}
    >
      <Order nextRoute="/epharmacy/upload" backRoute="/" location={location} />
    </Layout>
  )
}
