import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import SearchResultRow from "./SearchResults/SearchResultRow"
import styles from "./utils/search.module.scss"

const SearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  searchType,
  setSearchResults,
}) => {
  const sortedSearchResults = searchResults.sort((a, b) => {
    return a.closestMatch === "molecule" &&
      a.productTitle.includes("Value Brand") &&
      a.distance >= b.distance
      ? -1
      : 1
  })

  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && !isExactMatch && (
          <li
            className={classNames(
              "has-text-primary has-text-weight-bold is-size-7"
            )}
          >
            <FontAwesomeIcon icon={faExclamationCircle} /> Here are the closest
            items we have to the search term you entered.
          </li>
        )}
        {sortedSearchResults.map((result) => (
          <SearchResultRow
            searchType={searchType}
            result={result}
            handleClick={() => {
              if (onSelect) onSelect(result)
              setSearchResults([])
            }}
          />
        ))}
      </ol>
    </section>
  )
}

export default SearchResults
